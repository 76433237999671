import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/spedycja'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import ForwardingAdditionalText from '../sections/spedycja/ForwardingAdditionalText'

const Spedycja = () => {
  const { lang } = useLangContext()
  const { image, children } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/spedycja.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      children: allContentfulSpedycja(sort: { fields: name }) {
        nodes {
          name
          slug
          node_locale
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .filter((node) => !node.noCreatePage)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  const spedition1 = links.find(
    (link) => link.name === 'Spedycja Międzynarodowa'
  )
  const spedition2 = links.find((link) => link.name === 'Spedycja Krajowa')
  const linksSorted = [
    spedition1,
    spedition2,
    ...links.filter(
      (link) =>
        link.name !== 'Spedycja Międzynarodowa' &&
        link.name !== 'Spedycja Krajowa'
    ),
  ]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/spedycja.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Tiles data={linksSorted} />
      <ForwardingAdditionalText />
    </Layout>
  )
}

export default Spedycja
